var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "answerCard" },
    [
      _c(
        "van-popup",
        {
          style: { maxHeight: "70%" },
          attrs: { position: "bottom" },
          on: { "click-overlay": _vm.clickOverlay },
          model: {
            value: _vm.cardShow,
            callback: function($$v) {
              _vm.cardShow = $$v
            },
            expression: "cardShow"
          }
        },
        [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "mark" }, [
              _c("i", { staticClass: "iconfont icondatika" }),
              _vm._v(
                " " +
                  _vm._s(_vm.currentNum + 1) +
                  "/" +
                  _vm._s(_vm.questionsList.length)
              )
            ]),
            _c("h5", [_vm._v("答题卡")]),
            _c("i", {
              staticClass: "iconfont icon-guanbi",
              on: { click: _vm.onCancel }
            })
          ]),
          _c(
            "ul",
            _vm._l(_vm.questionsList, function(n, index) {
              return _c(
                "li",
                {
                  key: n.ID,
                  class: [
                    {
                      an:
                        _vm.AnswersListId.indexOf(n.ID) !== -1 ||
                        _vm.AnswersListId.indexOf(n.questionId) !== -1
                    },
                    { cur: _vm.currentNum == index }
                  ],
                  on: {
                    click: function($event) {
                      return _vm.goQuestion(n, index)
                    }
                  }
                },
                [
                  n.isMark
                    ? _c("i", { staticClass: "iconfont iconbiaoji" })
                    : _vm._e(),
                  _vm._v(" " + _vm._s(index + 1) + " ")
                ]
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }