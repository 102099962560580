<template>
  <div class="answerCard">
    <van-popup v-model="cardShow" position="bottom" :style="{maxHeight: '70%'}" @click-overlay=clickOverlay>
      <div class="title">
        <div class="mark"><i class="iconfont icondatika"></i> {{currentNum+1}}/{{questionsList.length}}</div>
        <h5>答题卡</h5>
        <i class="iconfont icon-guanbi" @click="onCancel"></i>
      </div>
      <ul>
        <li v-for="(n,index) in questionsList" :key="n.ID" @click="goQuestion(n,index)" :class="[{'an':AnswersListId.indexOf(n.ID) !== -1||AnswersListId.indexOf(n.questionId) !== -1},{'cur':currentNum==index}]">
          <i class="iconfont iconbiaoji" v-if="n.isMark"></i>
          {{index+1}}
        </li>
      </ul>
    </van-popup>
  </div>
</template>

<script>
import { Popup } from "vant";
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  name: "answerCard",
  components: {
    "van-popup": Popup
  },
  props: {
    questionsList: {
      type: Array,
      default: []
    },
    AnswersList: {
      type: Array,
      default: []
    },
    /* answerCardShow: {
      type: Boolean,
      default: false
    }, */
  },
  data () {
    return {
      AnswersListId: []
    };
  },
  watch: {
    cardShow () {
      this.AnswersListId = this.AnswersList.map((e) => {
        return e.questionId
      })
    }
  },
  computed: {
    ...mapState(['cardShow', 'questionsNum', 'currentNum']),
  },
  created () {
    console.log(this.questionsNum, '子------questionsNum')
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['updateCard', 'updateCurrentNum']),
    ...mapActions(['updateQuestionsNumAsync']),
    clickOverlay () {
      this.updateCard(false)
    },
    onCancel () {
      this.updateCard(false)
    },
    goQuestion (n, index) {
      this.updateCurrentNum(index)
      console.log('n------', n, index)
      this.updateCard(false)

    }
  }
};
</script>

<style lang="less" scoped>
.answerCard {
  .van-action-sheet {
    // position: relative;
    .van-action-sheet__header {
      width: 100%;
      position: fixed;
      left: 0;
      background: #fff;
      z-index: 100;
      &::after {
        content: "";
        display: block;
        position: absolute;
        left: -50%;
        width: 200%;
        height: 1px;
        background: #b6b5b5;
        transform: scale(0.5);
      }
    }
    .van-action-sheet__content {
      padding-top: 126px;
    }
  }
  .van-popup {
    .title {
      display: flex;
      justify-content: space-between;
      font-size: 30px;
      color: @color3;
      height: 90px;
      line-height: 90px;
      padding: 0 30px;
      border-bottom: 1px solid #ddd;
      width: 100%;
      position: fixed;
      z-index: 100;
      background: #fff;
      .mark {
        flex: 1;
        font-size: 28px;
        color: @color6;
        z-index: 110;
        i {
          color: @blueTheme;
          font-size: 30px;
        }
      }
      h5 {
        flex: 1;
        font-size: 30px;
        font-weight: 700;
        color: #333333;
      }
      i {
        font-size: 30px;
        color: @color6;
      }
    }

    ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding-left: 30px;
      padding-top: 120px;
      li {
        width: 74px;
        height: 74px;
        line-height: 74px;
        margin-right: 48px;
        margin-bottom: 44px;
        border-radius: 50%;
        border: 1px solid #dddddd;
        color: @color3;
        font-size: 26px;
        text-align: center;
        position: relative;
        // 已答题
        &.an {
          background: #edecec;
          border: 1px solid #dddddd;
        }
        //当前题
        &.cur {
          color: #0066b3;
          border: 1px solid #0066b3;
        }
        i {
          position: absolute;
          top: -20px;
          right: 15px;
          color: #0066b3;
          font-size: 30px;
          transform: scale(0.5);
        }
      }
    }
  }
}
</style>
